.App {
  line-height: 1.5em;
  text-align: center;
  color: rgba(0,0,0,.87);
}

.App-header {
  background-color: #444;
  border-bottom: 2px solid #fff;
  height: 210px;
  color: white;
}

.avatar {
  width: 80px;
  border-radius: 50%
}

.footer {
  background-color: #444;
  color: white;
  height: 50px;
  line-height: 50px;
  margin-top: 50px;
}

.App-title {
  font-size: 2.4em;
  line-height: 100px;
}

@media screen and (max-width: 480px) {
  .App-title {
    font-size: 1.5em;
  }
}

#contents h2 {
  font-size: 1.8em;
  margin: 30px 0 10px;
  padding-bottom: 7px;
  border-bottom: 1px solid #4173B3;
}

#contents h3 {
  -webkit-font-smoothing: antialiased;
  margin: 20px 0 5px;
}

#navi {
  background-color: #4173B3;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#navi a {
  display: inline-block;
  width: 120px;
  padding: 10px;
  border-left: 2px solid #fff;
  color: #fff;
  box-sizing: border-box;
}

@media screen and (min-width:450px) and (max-width: 740px) {
  #navi-box {
    width: 450px;
  }
  #navi a {
    width: 150px;
  }
  #navi a:nth-child(3) {
    border-right: 2px solid #fff;
  }
}

@media screen and (max-width: 450px) {
  #navi-box {
    width: 300px;
  }
  #navi a {
    width: 50%;
  }
  #navi a:nth-child(even) {
    border-right: 2px solid #fff;
  }
}

#navi a:hover {
  background-color: #759ccd;
}

#navi a:last-child {
  border-right: 2px solid #fff;
}

.card {
  width: 300px;
  padding: 10px;
  margin: 10px 10px;
  border: 2px solid #fff;
  font-weight: 400;
}

.card img {
  width: 100%;
}

ul {
  margin: 5px;
  font-weight: 400;
}

li {
  font-weight: 400;
}

#contents {
  width: 700px;
  margin: 0 auto;
  padding: 0 30px;
  text-align: left;
}

@media screen and (max-width: 480px) {
  #contents {
    width: 300px;
  }
}

#card_area {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
  color: #4173B3;
}

body {
  font-family:
          -apple-system,
          BlinkMacSystemFont,
          "Hiragino kaku Gothic ProN",
          MyYuGothicM,
          Meiryo,
          sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #161615;
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
}
