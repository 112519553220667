body {
  margin: 0;
  padding: 0;
  font-family: 'Gothic MB101 Light',
  'Hiragino Kaku Gothic W3 JIS2004',
  'メイリオ',
  'Meiryo',
  'Verdana',
  'ＭＳＰゴシック',
  'sans-serif';
}
